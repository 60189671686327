@font-face {
  font-family: "mori";
  src: url('/fonts/mori/PPMori-Regular.woff2') format('woff2');
  font-weight: 400;
  letter-spacing: -0.015em;
  font-display: swap;
}
@font-face {
  font-family: "mori";
  src: url('/fonts/mori/PPMori-SemiBold.woff2') format('woff2');
  font-weight: 600;
  letter-spacing: -0.015em;
  font-display: swap;
}
@font-face {
  font-family: "mori";
  src: url('/fonts/mori/PPMori-Bold.woff2') format('woff2');
  font-weight: 700;
  letter-spacing: -0.015em;
  font-display: swap;
}

:root {
  /* this part is exported from the old base.css */
  --primary: #ffffff;
  --primary-2: #f1f3f5;
  --secondary: #000000;
  --secondary-2: #111;
  --selection: var(--cyan);

  --text-base: #000000;
  --text-primary: #000000;
  --text-secondary: white;

  --hover: rgba(0, 0, 0, 0.075);
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);
  --cyan: #22b8cf;
  --green: #22B38C;
  --red: #da3c3c;
  --pink: #e64980;
  --purple: #f81ce5;
  --blue: #0070f3;
  --violet: #5f3dc4;
  --violet-light: #7048e8;
  --accents-0: #f8f9fa;
  --accents-1: #f1f3f5;
  --accents-2: #e9ecef;
  --accents-3: #dee2e6;
  --accents-4: #ced4da;
  --accents-5: #adb5bd;
  --accents-6: #868e96;
  --accents-7: #495057;
  --accents-8: #343a40;
  --accents-9: #212529;
  --font-mori: 'mori', -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', sans-serif;

  /* END OF EXPORTED PART */

  --base-color: #FD566F;
  --base-color-2: #3a3973;

  --content-base: #656565;

  --green-base: #22B38C;
  --accents-base-0: #f9fafa;
  --accents-base-1: #ebece4;
  --accents-base-2: #e9ecef;
  --accents-base-3: #f8fdfb;
  --accents-base-4: #fefefe;
  --accents-base-5: #f6f6f6;
  --accents-base-6: #2f2f2f;
  --accents-base-7: #ED2D4A;
  --accents-base-8: #8c8c8c;
  --accents-base-9: #a8a8c2;
  --accents-base-10: #ddd;
  --accents-base-11: #909090;
  --accents-base-12: #777777;
  --accents-base-13: #D8D8D8;
  --accents-base-14: #ffbfbf;
  --accents-base-15: #6c99f4;
  --accents-base-16: #d2ece6;
  --accents-base-17: #39bd97;
  --accents-base-18: #5194c4;
  --accents-base-19: #b9b9b9;

  --primary-text: #323232;
  --brown: #55402E;
  --light-blue: #ececf1;
  --light-brown: #F8E4DC;
  --light-brown2: #fcf5f1;
  --gray-dark: #4C4C4C;
  --gray-light: #efeff4;
  --gray-light2: #F8F9F9;
  --gray-light3: #d0d0d0;
  --gray-light4: #f2f2f2;
  --gray-light5: #c4cdd5;
  --blue-light: #EBF4FA;
  --error-red: #bb1919;
  --error-red-light: #ffb0b0;
  --success-green: #19BB90;
  --success-green-light: #C8FCED;

  --prod-accent-1: #f7f1ec;
  --prod-accent-2: #878787;
  --prod-accent-3: #e9ece4;
  --prod-accent-4: #f9f1ea;
  --prod-accent-5: #e7711b;
  --prod-accent-6: #787878;
  --prod-accent-7: #4267b2;
  --prod-accent-8: #f7f7f7;
  --prod-accent-9: #ffdcf9;
  --prod-accent-10: #63806a;
  --prod-accent-11: #f7fdfb;
  --prod-accent-12: #e8ebe5;
  --prod-accent-13: #e7e7ec;
  --prod-accent-14: #f2f8fc;
  --prod-accent-15: #F1C9CA;

   /* Hero Colors */
  --acai-120: #221533;
  --acai-100: #2C1847;
  --acai-80: #56466C;
  --acai-60: #807491;
  --acai-40: #ABA3B5;
  --acai-20: #D5D1DA;
  --acai-5: #F5F4F6;
  --unbleached-120: #E0D6C7;
  --unbleached-100: #F1E7D8;
  --unbleached-80: #F4ECE0;
  --unbleached-60: #F7F1E8;
  --unbleached-40: #F9F5EF;
  --unbleached-20: #FCFAF7;
  --unbleached-5: #FEFDFB;
  --pitaya-120: #CC2676;
  --pitaya-100: #FF379C;
  --pitaya-80: #FF5FB0;
  --pitaya-60: #FF87C4;
  --pitaya-40: #FFAFD7;
  --pitaya-20: #FFD7EB;
  --pitaya-5: #FFF0F8;
  --bud-120: #C5A0E5;
  --bud-100: #D9B1FB;
  --bud-80: #E1C1FC;
  --bud-60: #E8D0FD;
  --bud-40: #F0E0FE;
  --bud-20: #F7EFFE;
  --bud-5: #FDFBFF;
  --forest-120: #242517;
  --forest-100: #303123;
  --forest-80: #595A4F;
  --forest-60: #83837B;
  --forest-40: #ACADA7;
  --forest-20: #D6D6D3;
  --forest-5: #F5F5F4;
  --starfruit-120: #B0D217;
  --starfruit-100: #BFEA01;
  --starfruit-80: #CCEE34;
  --starfruit-60: #D9F267;
  --starfruit-40: #E5F799;
  --starfruit-20: #F2FBCC;
  --starfruit-5: #FCFEF2;
  --black-100: #111111;
  --black-80: #636363;
  --black-60: #909090;
  --black-40: #C1C1C1;
  --black-20: #E8E8E8;
  --black-10: #F5F5F5;
  --black-5: #FBFBFB;
  --error: #DD2727;
  --rambutan-100: #FF4F38;
  --rambutan-120: #D4422F;
  --rambutan-80: #FF6C59;
  --rambutan-60: #FF8A7A;
  --rambutan-40: #FFA79B;
  --rambutan-20: #FFC4BD;
  --rambutan-5: #FFDCD7;

   /* Utility Colors */
   --boulder: #333333;
  --sand: #F5F0E8;
  --paper: #F7F5F1;
  --pebble: #F2F2F2;
  --white: #FFFFFF;

  /* Old Branding */
  /* Primary Colours */
  --core-purple: var(--core-purple-90);
  --core-purple-10: #EBEBF1;
  --core-purple-20: #D7D7E3;
  --core-purple-40: #B0B0C6;
  --core-purple-80: #61618D;
  --core-purple-90: #393971;
  --core-purple-100:#2F2F5E;
  --core-rose: var(--core-rose-90);
  --core-rose-10: #FFEEF1;
  --core-rose-20: #FFDDE2;
  --core-rose-40: #FEBBC5;
  --core-rose-80: #FD788C;
  --core-rose-90: #FD566F;
  --core-rose-100: #DE3A58;
  --core-tree: var(--core-tree-90);
  --core-tree-10: #EBF8F5;
  --core-tree-20: #D7F2EA;
  --core-tree-40: #B0E5D5;
  --core-tree-80: #61CAAC;
  --core-tree-90: #39BD97;
  --core-tree-100: #2C9C7C;

  --core-grey-100: #282828;
  --core-grey-90: #4F4F4F;
  --core-grey-80: #838383;
  --core-grey-70: #A6A6A6;
  --core-grey-60: #C8C8C8;
  --core-grey-50: #D9D9D9;
  --core-grey-40: #EEEEEE;
  --core-grey-30: #F2F2F2;
  --core-grey-20: #F7F7F7;
  --core-grey-10: #FCFCFC;

  /* noissue UI Kit */
  --core-black: #111111;
  --core-grey-darkest: #4F4F4F;
  --core-grey-dark: #838383;
  --core-grey-mild: #C8C8C8;
  --core-grey-lighter: #EEEEEE;
  --core-grey-light: #F2F2F2;
  --core-grey-lightest: #F7F7F7;
  --core-white: #FFFFFF;
  --core-beak: #F8DEA1;
  --core-lilac: #D9D9E3;
  --core-blue-grey-light: #E2E5E8;
  --core-light-blue: #EBF4FA;
  --core-beige: #F7F1EC;
  --core-light-beige: #FBF8F6;
  --core-error: #EB2F4A;
  --core-light-error: #F6D9DD;

  /* Other old brand colors */
  --industry-navy: #3A3972;
  --industry-blue: #7CBBE6;
  --creative-yellow: #FBA819;
  --creative-teal: #278588;
  --creative-purple: #41357A;
  --eco-khaki: #697F6C;
  --eco-dark-green: #3C8D66;
  --eco-light-green: #EAF5E8;
}
